/** @description: 地图 */
<template>
  <div :id="id" class="sf-container" style="height: 100%">
    <!--  <div class="msgCon">
        <span>经度：{{ this.longitude }}</span> <br />
        <span>纬度：{{ this.latitude }}</span> <br />
        <span>地址：{{ this.address }}</span>
      </div> 
    </div> -->
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  name: "MapCon",
  components: {},
  props: {
    id: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    longitude: {
      //经度
      type: Number,
      default: 113.346421,
    },
    latitude: {
      //纬度
      type: Number,
      default: 23.102392,
    },
    // addressName: {
    //   //地址名称
    //   type: String,
    //   default: "",
    // },
  },
  data () {
    return {
      loadingTest: "正在加载数据 ...",
      passData: null,
      address: null,
      map: null,
    };
  },
  watch: {
    searchKey () {
      if (this.searchKey === "") {
        this.placeSearch.clear();
      }
    },
  },
  methods: {
    ininMap () {
      AMapLoader.load({
        key: "da5f7552c1c27104ad67ca7187d2a098", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "1.4.15"
      })
        .then((AMap) => {
          const map = new AMap.Map(document.getElementById(this.id), {
            zoom: 15,
            resizeEnable: true,
            viewMode: "2D",
            pitch: 50,
            center: [this.longitude, this.latitude],
          });

          const marker = new AMap.Marker({
            icon: require("@/assets/img/poi-marker-red.png"),
            position: [this.longitude, this.latitude],
            // offset: new AMap.Pixel(-13, -30),
          });

          marker.setMap(map);
          this.loadingTest = "";
        })
        .catch((e) => {
          throw (new Error('加载高德地图失败：' + e))
          // console.log(e);
        });
    },
  },
  created () { },
  // 西安悦秀城：   108.895643，34.149169
  // 广州国美智慧城： 113.346421， 23.102392
  // 长沙湘江九号： 112.963312，28.238877
  // 北京国美商都： 116.291636， 39.828689
  mounted () {
    this.ininMap();
  },
};
</script>

<style lang="scss" scoped>
.m-map {
  height: 100%;
  min-width: 500px;
  position: relative;
}
.m-map .map {
  width: 100%;
  height: 100%;
  min-height: 340px;
}
.m-map .search {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 285px;
  z-index: 1;
}
.m-map .search input {
  width: 180px;
  border: 1px solid #ccc;
  line-height: 20px;
  padding: 5px;
  outline: none;
}
.m-map .search button {
  line-height: 26px;
  background: #fff;
  border: 1px solid #ccc;
  width: 50px;
  text-align: center;
}
.m-map .result {
  max-height: 300px;
  overflow: auto;
  margin-top: 10px;
  height: 500px;
}
.msgCon {
  position: absolute;
  padding: 5px;
  background-color: #ffffff;
  top: 0;
  right: 0px;
}
// 调整提示文字样式
// /deep/.amap-info-content {
//   padding: 0;
//   a {
//     display: none;
//   }
//   .amap-info-sharp {
//     border-top: 8px solid #e78c22;
//   }
// }
</style>
