<template>
  <div class="project_wrap">
    <div
      class="project"
      :class="{
        'project1': index !== 0,
        'mt_4': index === 3,
        'mt_2': index === 1,
        'mt_7': index === 4,
      }"
      v-for="(item, index) in items"
      :key="item.ids"
    >
      <div class="bg_c" :class="'bg_' + item.idx">
        <div class="img_bg">
          <div class="img_title">
            <div class="img_left_title">
              <h1>{{ item.name }}</h1>
              <div class="bottom_line"></div>
              <!-- <h3>{{ item.desc }}</h3> -->
            </div>
          </div>
        </div>
      </div>
      <div class="foot_section">
        <div class="content">
          <ul class="left-list">
            <li>
              <div class="bottom_line"></div>
              <span class="title">项目位置：</span>
              <span class="content">{{ item.position }}</span>
            </li>
            <li>
              <div class="bottom_line"></div>
              <span class="title">项目体量：</span>
              <span class="content">{{ item.dimension }}</span>
            </li>
            <li>
              <div class="bottom_line"></div>
              <span class="title">商业层高：</span>
              <span class="content">{{ item.height }}</span>
            </li>
            <li>
              <div class="bottom_line"></div>
              <span class="title">开业时间：</span>
              <span class="content">{{ item.openingTime }}（预计）</span>
            </li>
          </ul>
          <ul class="right-list">
            <li>
              <div class="bottom_line"></div>
              <span class="title">项目定位：</span
              ><span class="content">{{ item.projectPosition }}</span>
            </li>
            <li v-if="item.businessVolume">
              <div class="bottom_line"></div>
              <span class="title">商业体量：</span>
              <span class="content">{{ item.businessVolume }}㎡</span>
            </li>
            <li>
              <div class="bottom_line"></div>
              <span class="title">商业楼层：</span>
              <span class="content">{{ item.floor }}</span>
            </li>
            <li>
              <div class="bottom_line"></div>
              <span class="title">停车场数量：</span>
              <span class="content">{{ item.parkingNumber }}</span>
            </li>
          </ul>
        </div>
        <ul class="bottom-list">
          <li>
            <div class="bottom_line"></div>
            <span class="title" style="width: 17%">商业业态定位：</span>
            <span class="content">{{ item.formatsPosition }}</span>
          </li>
        </ul>
        <div class="img_section">
          <div class="img_section-left">
            <!-- @ininMap="ininMap" -->
            <MapCon
              :id="'mapCon' + item.idx"
              :longitude="item.longitude"
              :latitude="item.latitude"
            >
            </MapCon>
          </div>

          <img class="img_section-right" :src="item.image" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MapCon from "@/components/MapCon";
//import AMapLoader from "@amap/amap-jsapi-loader";

export default {
  data() {
    return {
      visible: true,
      items: [
        {
          idx: "0",
          image: require("@/assets/img/mall/changsha.jpg"),
          name: "乐活秀长沙湘江玖号",
          desc: "国美家自持商业项目",
          position: "长沙市岳麓滨江新城",
          dimension: "17.3万㎡",
          height: "2.68m - 10m",
          openingTime: "2022年12月31日",
          // businessVolume: "-",
          formatsPosition:
            "泛国美线下体验中心、共享生活空间、情景生活、体验空间、家庭聚乐空间、商务休闲延伸五大板块。",
          projectPosition: "城市级滨江商业地标/夜经济娱乐网红旅游目的地",
          floor: "B1 - F4",
          parkingNumber: "915个",
          longitude: 112.963312,
          latitude: 28.238877,
          // addressName: "湘江玖号",
        },
        {
          idx: "1",
          image: require("@/assets/img/mall/guangzhou.jpg"),
          name: "乐活秀广州智慧城",
          desc: "国美家自持商业项目",
          position: "广州市海珠琶洲",
          dimension: "24万㎡",
          height: "4.5m - 8.1m",
          openingTime: "2023年05月01日",
          formatsPosition:
            "商务会客厅、商务社交场，以商务社交、休闲娱乐、个性化消费、文化休闲商业业态为主。",
          projectPosition: "双子塔地标/智慧型总部写字楼",
          businessVolume: "4.6万",
          floor: "B1 - F5",
          parkingNumber: "791个",
          longitude: 113.346421,
          latitude: 23.102392,
          // addressName: "国美·智慧城",
        },
        {
          idx: "2",
          image: require("@/assets/img/mall/xian.jpg"),
          name: "乐活秀西安悦秀城",
          desc: "国美家自持商业项目",
          position: "西安市长安区学府大街与书香路交口",
          dimension: "24万㎡",
          height: "3.9m - 8.7m",
          openingTime: "2022年10月1日",
          formatsPosition:
            "以共享体验为核心驱动力，带动相关业态发展，以零售展示、儿童业态、特色餐饮、娱乐休闲四大板块相互融合辅助构成其核心竞争力，分为美丽新市集、休闲益智园、活力欢聚场、活力娱乐城、美味鉴赏家五大业态功能组团。",
          projectPosition: "集商业、酒店、公寓LOFT于一体的城市综合体",
          businessVolume: "2.2万",
          floor: "F1 - F6（含停车场）",
          parkingNumber: "1572个",
          longitude: 108.895643,
          latitude: 34.149169,
          // addressName: "西安悦秀城",
        },
        {
          idx: "3",
          image: require("@/assets/img/mall/beijing.jpg"),
          name: "乐活秀北京国美商都",
          desc: "国美家自持商业项目",
          position: "北京市南四环丰台科技园",
          dimension: "56万㎡",
          height: "5.5m - 6m",
          openingTime: "2023年01月01日",
          formatsPosition:
            "运用全新的理念诠释了次时代家庭生活方式，营造了家庭生活的“新维度”，通过打造全新的共享生活场景，助力当代都市人群塑造更健康、更趣味、更充实、更多元的全新生活方式。利用共享模式将个人生活、家庭生活、家庭娱乐、展示体验等多元业态进行融合，实现了线上到线下的统一。",
          projectPosition: "集商业、酒店公寓、写字楼为一体的大型城市综合体",
          businessVolume: "32万",
          floor: "B1 - F7",
          parkingNumber: "2800个",
          longitude: 116.291636,
          latitude: 39.828689,
          // addressName: "国美商都",
        },
        {
          idx: "4",
          image: require("@/assets/img/mall/chengdu.jpg"),
          name: "乐活秀成都万和广场",
          desc: "国美家自持商业项目",
          position: "成都青羊区八宝街",
          dimension: "5.8万㎡",
          height: "3.1m - 5.1m",
          openingTime: "2022年12月24日",
          formatsPosition: "共享餐饮，共享教培，共享零售，美食美客四大板块。",
          projectPosition: "打造创新、共享、多元的特色社区型商业",
          businessVolume: "2.4万",
          floor: "B2 - F5",
          parkingNumber: "672个",
          longitude: 104.061732,
          latitude: 30.67154,
          // addressName: "国美商都",
        },
      ],
    };
  },
  // 注册子组件
  components: {
    MapCon,
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>

<style lang="scss" scoped>

.mt_4 {
  margin-top: 46px;
}
.mt_2 {
  margin-top: 20px;
}

.mt_7 {
  margin-top: 70px;
}
.project1 {
  // margin-top: 40px;
}
.bg_c {
  background: url("~@/assets/img/bg_project_1.png") no-repeat center 0;
  min-width: 1200px;
  .bg_logo {
    width: 207px;
    height: 55px;
    left: 50px;
    top: 12px;
    position: absolute;
    .img1 {
      float: left;
      width: 55px;
      height: auto;
      object-fit: contain;
      //position: absolute;
      top: 10%;
      left: 0;
    }
    .bg_logo_name {
      float: right;
      font-size: 12px;
      color: white;
      padding-top: 20;
    }
  }
  .img_bg {
    width: 1200px;
    height: 920px;
    margin: auto;
    position: relative;
    .img_title {
      padding-top: 40%;
    }
    .img_left_title {
      display: inline-block;
    }
    h1 {
      font-size: 40px;
      font-family: Hiragino Sans GB;
      font-weight: 600;
      line-height: 40px;
      color: #ffffff;
      opacity: 1;
    }
    .bottom_line {
      display: inline-block;
      width: 100px;
      height: 8px;
      background: linear-gradient(to right, #f6ba4f, #ea8427);
      border-radius: 4px;
      opacity: 1;
      text-align: bottom;
      top: 10px;
      position: relative;
      margin: auto;
    }
    h3 {
      font-size: 24px;
      font-family: Hiragino Sans GB;
      font-weight: 300;
      line-height: 24px;
      color: #ffffff;
      margin-top: 38px;
      text-align: center;
      opacity: 1;
    }
  }
}
.foot_section {
  background-color: #ffffff;
  width: 1200px;
  min-height: 700px;
  margin: auto;
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;

  .content {
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    margin: 0 20px 0 40px;
  }
  .left-list {
    width: 45%;
    font-size: 18px;
  }
  .right-list {
    width: 45%;
  }
  .bottom-list {
    margin: 0 40px;
    margin-bottom: 60px;

    li {
      min-height: 33px;
    }
  }
  .bottom_line {
    width: 11px;
    height: 10px;
    background: linear-gradient(to right, #f6ba4f, #ea8427);
    border-radius: 5px;
    opacity: 1;
    text-align: center;
    top: 10px;
    position: relative;
  }
  ul {
    list-style: none;
  }
  ul li {
    //   margin-top:40px;
    line-height: 30px;
    min-height: 66px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    display: flex;
    align-items: flex-start;
  }
  ul li span {
    width: 72%;
    font-size: 22px;
    font-family: Microsoft YaHei;
    line-height: 26px;
    display: inline-block;
  }
  ul li .content {
    font-size: 20px;
    margin-left: -20px;
  }
  .title {
    display: inline-block;
    margin-left: 20px;
    width: 35%;
    font-size: 22px;
    font-weight: 500;
    color: #000000;
  }
  .right-list p {
    float: right;
    margin: 0px;
    max-width: 430px;
  }
  .bottom-list p {
    float: right;
    margin: 0px;
    max-width: 1003px;
  }
  .img_section {
    float: left;
    &-left {
      // margin-top: 21px;
      margin-right: 52px;
      width: 574px;
      height: 345px;
      border-radius: 10px;
      float: left;

      /deep/.amap-layer {
        border-radius: 10px;
      }
    }
    &-right {
      // margin-top: 21px;
      width: 574px;
      height: 345px;
      float: right;
      border-radius: 10px;
    }
  }
}
.bg_0 {
  height: 1000px;
  background-image: url("~@/assets/img/bg_project_1.png");
}
.bg_1 {
  background-image: url("~@/assets/img/bg_project_2.png");
}
.bg_2 {
  background-image: url("~@/assets/img/bg_project_3.png");
}
.bg_3 {
  background-image: url("~@/assets/img/bg_project_4.png");
}

.bg_4 {
  background-image: url("~@/assets/img/bg_project_5.png");
}
</style>
